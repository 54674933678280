export default class FAQ {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.faq = {
      translations: [
        { id: null, question: '', answer: '', lang: 'ar' },
        { id: null, question: '', answer: '', lang: 'en' }
      ]
    }
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.faq.translations[0].id = itemAr ? itemAr.id : null
      this.faq.translations[0].question = itemAr ? itemAr.question : ''
      this.faq.translations[0].answer = itemAr ? itemAr.answer : ''
      this.faq.translations[1].id = itemEn ? itemEn.id : null
      this.faq.translations[1].question = itemEn ? itemEn.question : ''
      this.faq.translations[1].answer = itemEn ? itemEn.answer : ''
    } else {
      this.setInitialValue()
    }
  }
}
